.vertical-timeline-element-content {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.08);
    padding: 20px;
    border-radius: 10px;
    
    border: 1px solid rgba(0, 0, 0, 0.1); /* Light border for 3D effect */
}

.vertical-timeline-element-content:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
}
