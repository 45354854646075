/* src/components/ProjectCard.css */
.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
  cursor: pointer; /* Pointer cursor for clickability */
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 30px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

p .card-description{
  text-align: center; /* Ensure the description text is centered */
  position: relative;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 16px;
}

.card-title {
  font-size: 18px;
  color : #333;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
}

.card-description {
  font-size: 14px;
  color: #333;
  margin-bottom: 12px;
  text-align: center; /* Center the description text */
}

.card-technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
  justify-content: center;
}

.tech-bubble {
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 12px;
  display: grid;
  color: white; /* Make text color white for better contrast */
}

.card-tag {
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  color: white; /* Make text color white for better contrast */
  text-align: center;
  margin: 0 auto;
  position: absolute;
  bottom: 16px; /* Adjust this value to set distance from bottom */
  left: 50%;
  transform: translateX(-50%);
  width: auto;
}
