.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-container select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23777" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 24px;
}

.background-container {
  background-image: url('./imgs/layered-peaks-haikei.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .background-container {
    background-size: cover;
    background-position: bottom center;
  }
}

@media (max-width: 480px) {
  .background-container {
    background-size: cover;
    background-position: bottom center;
  }
}
