.NavBar {
    background-color: rgb(37, 37, 37);
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 0 16px; 
    height: 60px; 
}

.NavBar h1 {
    color: #eec233;
    text-decoration: none;
    margin: 0;
    font-size: 28px;
    display: flex;
    align-items: center; 
    height: 100%; 
}

.NavLink {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.NavLink li {
    margin-left: 20px;
}

.NavLink li a {
    display: block;
    color: white;
    text-align: center;
    font-size: 20px;
    padding: 14px 16px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    height: 100%; 
    display: flex;
    align-items: center; 
}

.NavLink li a:hover {
    background-color: #ddd;
    color: black;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center; 
    height: 60px; 
}

.hamburger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
    .NavLink {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
    }

    .NavLink.active {
        display: flex;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: rgb(37, 37, 37);
        width: 100%;
        z-index: 1; /* Ensure it's above other content */
    }

    .hamburger {
        display: flex;
    }

    .NavLink li {
        margin: 0;
        border-bottom: 1px solid #444;
    }

    .NavLink li a {
        padding: 10px 0;
    }
}
