/* src/components/Gallery.css */
.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-container {
display: flex;
justify-content: center;
margin: 20px 0;
text-align: center;
}

.search-container select {
padding: 10px;
font-size: 16px;
text-align: center;
width: 300px;
border: 1px solid #ccc;
border-radius: 25px; 
background-color: #f9f9f9; 
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
transition: all 0.3s ease; 
appearance: none; 
}

.search-container select:hover {
border-color: #aaa;
}

.search-container select:focus {
border-color: #777; 
outline: none;
box-shadow: 0 0 0 3px rgba(100, 100, 255, 0.2); 
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}
